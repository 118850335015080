<template>
    <Navbar/>
    <div class="carta ">
      <div class="row container carta1">
        
<!-- SECCIÓN CAFE Y SALADOS -->
        <div class="col-md-5">
          <div class="container">
          <h4 class="titulo my-2">CAFÉ</h4>
            <div class="row">
              <div class="col-6">
                
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>Jarrito</p></div>
                  <div class="col-4 text-center"><p>Grande</p></div>
                  <div class="col-4 text-center"><p>XL</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Café</h5>
                <p>C/leche, cortado, lágrima, negro.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$2500</p></div>
                  <div class="col-4"><p>$3000</p></div>
                  <div class="col-4"><p>$3200</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Capuccino</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$3000</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Chocolatada</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$3000</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Matecocido, Té</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$2500</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Café Marietta</h5>
                <p>Café c/leche, hielo, helado de crema.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$4000</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Café Afogatto</h5>
                <p>Bocha de helado, café expresso y nueces.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$3500</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Submarino</h5>
                <p>A la leche caliente le agregamos una barrita de chocolate águila.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$3000</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
          </div>
          <div class="container my-2">
          <h4 class="titulo">SALADOS</h4>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Panes tostados</h5>
                  <p>2 panes tostados de salvado.</p>
                </div>
                <div class="col-4">
                  <p>$1200</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Como en casa</h5>
                  <p>2 panes tostados de salvado con mermelada y queso untable.</p>
                </div>
                <div class="col-4">
                  <p>$2100</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Mbeyú simple</h5>
                  <p>con queso.</p>
                </div>
                <div class="col-4">
                  <p>$3500</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Mbeyú relleno</h5>
                  <p>Doble queso y jamón.</p>
                </div>
                <div class="col-4">
                  <p>$5300</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Tarta napolitana</h5>
                  <p>Huevo, tomate, queso y jamón.</p>
                </div>
                <div class="col-4">
                  <p>$2500</p>
                </div>
            </div>
            <div class="row">
              <div class="col">
              
              </div>
              <div class="col">
                <div class="card" style="width: 18rem;">
                  <div class="card-body ">
                    <h5 class="card-title text-center text-white">CHIPITAS</h5>
                    <p class="card-text">Podés llevarlas congeladas x kg.</p>
                    <div class="text-center">
                      <div class="row">
                        <div class="col"><p class="text-white card-link">x 100gr</p></div>
                        <div class="col"><p class="text-white card-link">x Kg</p></div>
                      </div>
                      <div class="row">
                        <div class="col"><span class="card-link card-footer">$1400</span></div>
                        <div class="col"><span class="card-link card-footer">$14000</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
              
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card" style="width: 18rem;">
                  <div class="card-body text-center">
                    <h5 class="card-title  text-white">PAN DE QUESO</h5>
                    <p class="card-text">Irresistibles!</p>
                    <span class="text-white card-link">x Unidad</span>
                    <br>
                    <br>
                    <span class="card-link card-footer">$600</span>
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div> 
            <br>
            <h4 class="titulo my-2">SANDWICHES</h4>
            <div class="row">
              <div class="col-6">
                
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>S/verduras</p></div>
                  <div class="col-6"><p>C/Verduras</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Carlitos</h5>
                <p>Pan de miga, jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$4200</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Salvado</h5>
                <p>Pan salvado, jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3000</p></div>
                  <div class="col-6"><p>$4000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Pebete</h5>
                <p>Pan pebete, jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3000</p></div>
                  <div class="col-6"><p>$</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Pebete Milán</h5>
                <p>Pan pebete, salame milán y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3500</p></div>
                  <div class="col-6"><p>$4000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Pebete de Pollo/Atún</h5>
                <p>Crema de pollo/atún, queso y rúcula.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p></p>$4000</div>
                  <div class="col-6"><p>$</p></div>
                </div>
              </div>
            </div> 
            <div class="row">
              <div class="col-6">
                <h5 class="item">Pan Francés</h5>
                <p>Pan francés, salame milán y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3500</p></div>
                  <div class="col-6"><p>$4000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Franchute de Pollo/Atún</h5>
                <p>Pan Francés, crema de pollo/atún, queso y rúcula.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p></p></div>
                  <div class="col-6"><p>$4000</p></div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-6">
                <h5 class="item">Croissant</h5>
                <p>Medialuna salada, jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3000</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Triple de verduras</h5>
                <p>Jamón y queso, tomate y lechuga.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$6000</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Sacramento</h5>
                <p>Medialuna dulce con jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3000</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Fosforito</h5>
                <p>Factura azucarada con jamón y queso.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$3000</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Bandeja Sandwiches</h5>
                <p>Pollo, jyq, verduras, atún.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$6000</p></div>
                  <div class="col-6"><p></p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!-- SECCIÓN BEBIDAS Y DULCES -->
        <div class="col-md-4">
          <div class="container">
          <h4 class="titulo my-2">¿QUÉ TOMAR?</h4>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Licuados</h5>
                  <p>Frutilla, maracuyá, ananá, multifruta, banana, durazno, melón.</p>
                </div>
                <div class="col-4">
                  <p>$3600</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Banana Split</h5>
                  <p>Bananas, dulce de leche y topping de crema.</p>
                </div>
                <div class="col-4">
                  <p>$4000</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Batido de óreo</h5>
                  <p>Galletitas de óreo, leche, helado de crema y topping de crema.</p>
                </div>
                <div class="col-4">
                  <p>$4000</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Jugo de Naranja</h5>
                  <p>Natural</p>
                </div>
                <div class="col-4">
                  <p>$3000</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Jugo de Naranja XL</h5>
                  <p>Natural</p>
                </div>
                <div class="col-4">
                  <p>$3200</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Coca Cola</h5>
                  <p>Personal.</p>
                </div>
                <div class="col-4">
                  <p>$2500</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Agua Saborizada</h5>
                </div>
                <div class="col-4">
                  <p>$1200</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Agua c/ o sin gas</h5>
                </div>
                <div class="col-4">
                  <p>$1200</p>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                  <h5 class="item">Cerveza Heineken</h5>
                  <p>Porron x 330 ml.</p>
                </div>
                <div class="col-4">
                  <p>$</p>
                </div>
            </div>
          </div>
          <div class="container">
          <h4 class="titulo my-2">DULCES</h4>
            <div class="row">
              <div class="col-6">
                
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>Unidad</p></div>
                  <div class="col-4 text-center"><p>1/2 Doc.</p></div>
                  <div class="col-4 text-center"><p>Doc.</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Facturas</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$800</p></div>
                  <div class="col-4"><p>$4500</p></div>
                  <div class="col-4"><p>$8000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Alfajores de maicena</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$800</p></div>
                  <div class="col-4"><p>$4500</p></div>
                  <div class="col-4"><p>$8000</p></div>
                </div>
                <br>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-6">
                <h5 class="item">Alfajores Tía María</h5>
                <p>Marplatenses bañados en chocolate</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$000</p></div>
                  <div class="col-4"><p>$0000</p></div>
                  <div class="col-4"><p>$0000</p></div>
                </div>
                <br>
              </div>
            </div> -->
            <div class="row">
              <div class="col-6">
                <h5 class="item">Scones dulces y salados</h5>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$800</p></div>
                  <div class="col-4"><p>$4500</p></div>
                  <div class="col-4"><p>$8000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Bandeja Dulce</h5>
                <p>4 mini brownie, 4 mini coco, 4 alfajor mini, 4 frolitas</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4"><p>$8000</p></div>
                  <div class="col-4"><p></p></div>
                  <div class="col-4"><p></p></div>
                </div>
              </div>
            </div>
            <h4 class="titulo my-2">BUDINES</h4>
            <div class="row">
              <div class="col-6">
                
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>Porción</p></div>
                  <div class="col-6"><p>Entera</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Glasé</h5>
                <p>Budin de naranja con chocolate blanco bañado en glasé de naranja.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$1000</p></div>
                  <div class="col-6"><p>$9000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Hamburgues</h5>
                <p>Budin de vainilla con chip de chocolate y nueces.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$1000</p></div>
                  <div class="col-6"><p>$9000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Inglés</h5>
                <p>Tradicional con todos los frutos.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$1000</p></div>
                  <div class="col-6"><p>$9000</p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h5 class="item">Galés</h5>
                <p>Budin de vainilla, miel, frutos, nueces y ciruelas presidente.</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6"><p>$1000</p></div>
                  <div class="col-6"><p>$9000</p></div>
                </div>
              </div>
            </div>
            <h4 class="titulo my-2">CUPCAKES RELLENOS</h4>
            <div class="row">
              <div class="col-9">
                <h5 class="item">Tradicionales</h5>
                <p>Con mouse de dulce de leche.</p>
              </div>
              <div class="col-3">
                <div class="row">
                  <p>$2000</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                <h5 class="item">Red Velvet</h5>
                <p>Con cream chesse.</p>
              </div>
              <div class="col-3">
                <div class="row">
                  <p>$2000</p>
                </div>
              </div>
            </div>
          </div>
        </div>

<!-- SECCIÓN COMBOS -->
        <div class="col-md-3">
          <div class="container">
            <h4 class="titulo my-2">COMBOS</h4>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO 1</h4>
                    <h5 class="card-text">"Como en casa"</h5>
                    <p>1 café con leche + 1 jugo de naranja chico + 2 panes tostados + mermelada y queso untable</p>
                    <span class="card-link card-footer">$5800</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO 2</h4>
                    <h5 class="card-text">"Estilo Porteño"</h5>
                    <p>1 café con leche + 1 jugo de naranja chico + 1 fosforito o 1 sacramento</p>
                    <span class="card-link card-footer">$6500</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO 3</h4>
                    <h5 class="card-text">"Saludable"</h5>
                    <p>1 café + 1 jugo de naranja chico + 1 tostada baba + porción de fruta con hilos de miel y nueces</p>
                    <span class="card-link card-footer">$7000</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO 4</h4>
                    <h5 class="card-text">"Chipitas"</h5>
                    <p>1 café + 1 jugo de naranja + chipitas</p>
                    <span class="card-link card-footer">$5200</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO</h4>
                    <h5 class="card-text">"Carlitos"</h5>
                    <p>1 café + 1 jugo de naranja + tostado de jamón y queso</p>
                    <span class="card-link card-footer">$7600</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO</h4>
                    <h5 class="card-text">"Pom"</h5>
                    <p>Manzanas con nuez, almendra y miel + huevo revuelto + queso crema + 2 tostadas + un jugo de naranja y un jarrito de café</p>
                    <span class="card-link card-footer">$7800</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO</h4>
                    <h5 class="card-text">"Mbeyu"</h5>
                    <p>1 cocido quemado y 1 mbeyú simple</p>
                    <span class="card-link card-footer">$5600</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="card text-center" style="width: 20rem;">
                  <div class="card-body ">
                    <h4 class="card-title  text-white">COMBO</h4>
                    <h5 class="card-text">"Mbeyu"</h5>
                    <p>1 cocido quemado y 1 mbeyú relleno</p>
                    <span class="card-link card-footer">$7500</span> 
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="carta2">
    <br>
      <div class="row container">
      <!-- TORTAS -->
        <div class="col-md-6">
          <h1 class="titulo-torta">TORTAS</h1>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-2 pt-2 "><p>Porción</p></div>
            <div class="col-2 pt-2 item-torta"><p>Entera</p></div>
          </div>
          <!-- <div class="row">
            <div class="col-8">
              <h5 class="item">Torta Lulú</h5>
              <p>Tambor de chocolate, relleno con crema y salsas de frutillas, con mousse de chocolate.</p>
            </div>
            <div class="col-2 pt-2"><p></p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div> -->
          <div class="row">
            <div class="col-8">
              <h5 class="item">Chajá</h5>
              <p>Bizcochuelo de vainilla, crema con durazno, dulce de leche y merengue italiano tostado.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$18000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Oreo</h5>
              <p>Bizcochuelo de vainilla, crema, óreos y dulce de leche.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$18000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Vacalina</h5>
              <p>Bizcochuelo de vainilla, crema y dulce de leche.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$18000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Mousse de Choco</h5>
              <p>Bizcochuelo de vainilla, genoise de choco y mousse de choco.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$20000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Mousse de D. de Leche</h5>
              <p>Bizcochuelo de vainilla, mousse de dulce de leche y merengue italiano tostado.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Selva Negra</h5>
              <p>Bizcochuelo genoise de choco y crema con cerezas.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$18000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Matilda</h5>
              <p>Bizcochuelo de choco y dulce de leche, bañada en chocolate.</p>
            </div>
            <div class="col-2 pt-2"><p>$4500</p></div>
            <div class="col-2 pt-2"><p>$20000</p></div>
          </div>
        </div>
        <div class="col-md-6 tortas">
          <!-- <div class="row">
            <div class="col-8">
              <h5 class="item">Torta Carioca</h5>
              <p>Crema Brasileña con leche condensada, ananá y coco rallado.</p>
            </div>
            <div class="col-2 pt-2"><p></p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div> -->
          <!-- <div class="row">
            <div class="col-8">
              <h5 class="item">Vacalina c/ chip de chocolate</h5>
              <p>Dulce de leche, crema y chips de chocolate.</p>
            </div>
            <div class="col-2 pt-2"><p></p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div> -->
          <div class="row">
            <div class="col-8">
              <h5 class="item">Red Velvet</h5>
              <p>Torta de terciopelo rojo relleno con una deliciosa cream cheese.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Moka</h5>
              <p>Bizcochuelo de vainilla, crema de moka y mousse de dulce de leche con nueces.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$18000</p></div>
          </div>
          <!-- <div class="row">
            <div class="col-8">
              <h5 class="item">Condensa</h5>
              <p>Bizcochuelo de vainilla y crema con salsa de frutos rojos.</p>
            </div>
            <div class="col-2 pt-2"><p></p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div> -->
          <div class="row">
            <div class="col-8">
              <h5 class="item">Argenta</h5>
              <p>Bizcochuelo alfajor, dulce de leche.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$20000</p></div>
          </div>
        </div>
      </div>
      <div class="row container">
        <!-- TARTAS -->
        <div class="col-md-6">
          <h1 class="titulo-torta">TARTAS</h1>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-2 pt-2 "><p>Porción</p></div>
            <div class="col-2 pt-2 item-torta"><p>Entera</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Lemon Pie</h5>
              <p>Base de tarta, fina capa de d. de leche, deliciosa crema de limón terminada con merengue italiano.</p>
            </div>
            <div class="col-2 pt-2"><p>$4000</p></div>
            <div class="col-2 pt-2"><p>$15000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta de coco</h5>
              <p>Base de tarta, dulce de leche y crema con coco.</p>
            </div>
            <div class="col-2 pt-2"><p>$2800</p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Cheescake</h5>
              <p>Base de tarta, d. de leche, mascarpone con salsas de frutos rojos o maracuyá.</p>
            </div>
            <div class="col-2 pt-2"><p>$4000</p></div>
            <div class="col-2 pt-2"><p>$25000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta de Ricota</h5>
              <p>Base de tarta, d. de leche, crema de ricota con salsas de frutos rojos o maracuyá.</p>
            </div>
            <div class="col-2 pt-2"><p>$2800</p></div>
            <div class="col-2 pt-2"><p>$25000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Crumble de Manzana</h5>
              <p>Base de tarta, compota de manzana y crumble.</p>
            </div>
            <div class="col-2 pt-2"><p>$2800</p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Brownie</h5>
            </div>
            <div class="col-2 pt-2"><p>$2800</p></div>
            <div class="col-2 pt-2"><p></p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Chocotorta</h5>
            </div>
            <div class="col-2 pt-2"><p>$4500</p></div>
            <div class="col-2 pt-2"><p>$25000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta Oreo</h5>
              <p>Base de óreos, una capa de d. de leche mascarpone con pasta óreo.</p>
            </div>
            <div class="col-2 pt-2"><p>$4500</p></div>
            <div class="col-2 pt-2"><p>$25000</p></div>
          </div>
        </div>
        <div class="col-md-6 tartas">
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tiramisusa</h5>
              <p>Base de galletitas de vainilla, d. de leche, crema de café y cacao.</p>
            </div>
            <div class="col-2 pt-2"><p>$4500</p></div>
            <div class="col-2 pt-2"><p>$25000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Pastafrola</h5>
              <p>Membrillo, d. de leche, pastelera.</p>
            </div>
            <div class="col-2 pt-2"><p>$1800</p></div>
            <div class="col-2 pt-2"><p>$9000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Marquise de choco</h5>
              <p>Base de brownie con dulce de leche, mascarpone y crema con hilos de choco.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Banana Split</h5>
              <p>Base de tarta, bananas, mascarpone de d.de leche y crema con hilos de chocolate.</p>
            </div>
            <div class="col-2 pt-2"><p></p></div>
            <div class="col-2 pt-2"><p>$</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta de Durazno o Manzana</h5>
              <p>Base de tarta, crema pastelera, durazno o manzana.</p>
            </div>
            <div class="col-2 pt-2"><p>$</p></div>
            <div class="col-2 pt-2"><p>$</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta de Frutilla</h5>
              <p>Base de tarta, crema con frutillas</p>
            </div>
            <div class="col-2 pt-2"><p>$4000</p></div>
            <div class="col-2 pt-2"><p>$15000</p></div>
          </div>
          <div class="row">
            <div class="col-8">
              <h5 class="item">Tarta Multifrutal</h5>
              <p>En estación.</p>
            </div>
            <div class="col-2 pt-2"><p>$4000</p></div>
            <div class="col-2 pt-2"><p>$15000</p></div>
          </div>
        </div>
      </div>
    </div>
    
    <Footer />
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    Navbar, Footer
  }
}

</script>
<style scoped>
.carta{
  background-color: #409690;
}
.carta2{
  background-color: rgb(224, 135, 150);
}
.titulo{
  background-color: #245e5a;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
h5{
  color: rgb(233, 178, 187);
  font-size: 20px;
}
h4{
  font-size: 25px;
  text-align: center;
}
p{
  color: white;
  font-size: 16px;
}
span{
  font-size: 15px;
}
.item{
  border-bottom: 1px dashed #ffffff;
}
.card{
  background-color: #245e5a;
}
.card-footer{
  background-color: #ffffff;
  padding: 5px;
  border-radius: 10%;
}
.item-torta{
  padding-left: 15px;
}
.titulo-torta{
  background-color: rgb(255, 145, 145);
  text-align: center;
  color: rgb(207, 24, 24);
  padding: 3px;
  border-radius: 10px;
  font-size: xx-large;
  font-weight: 600;
}
@media (min-width: 600px){
  .row{
    margin-left: 10px;
  }
  .tortas, .tartas{
    margin-top: 80px;
  }
}
</style>